import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const myOrderRoutes = [
    {
        path: '/my-orders',
        name: 'orders.index',
        component: () => import("@frontend_pages/my-orders/MyOrderIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'My orders',
            guard: ERouteGuard.AUTHORISED
        }
    },
    {
        path: '/my-orders/:id',
        name: 'orders.folder',
        component: () => import("@frontend_pages/my-orders/MyOrderFolder.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'My orders | Folder',
            guard: ERouteGuard.AUTHORISED
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
