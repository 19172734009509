import type {RouteMeta, RouteParams, RouteRecordRaw} from 'vue-router';
import type {RoutesType} from "@frontend_app/router";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

declare module 'vue-router' {
    interface RouteMeta {
        guard?: ERouteGuard,
        needSubscriptionOption?: ESubscriptionTariffOptionCode,
    }
}

export type FrontendRouteRecordType = Omit<RouteRecordRaw, 'name' | 'children' | 'meta'> & {
    name: string,
    children?: readonly FrontendRouteRecordType[],
    meta?: RouteMeta
}

export type GetRouteNameType<T extends FrontendRouteRecordType> =
    T extends { children: readonly FrontendRouteRecordType[] }
        ? T['name'] | GetRoutesNamesType<T['children']>
        : T['name'];

export type GetRoutesNamesType<T extends readonly FrontendRouteRecordType[]> = GetRouteNameType<T[number]>

/* @ts-ignore */
export type RoutesNamesType = GetRoutesNamesType<RoutesType>;

export type RouteToType = {
    name: RoutesNamesType,
    params?: RouteParams,
    query?: object
}

export enum ERouteGuard {
    ANY = 'any',
    GUEST = 'guest',
    AUTHORISED = 'authorised',
}
