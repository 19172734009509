<script setup lang="ts">
import {cn} from '@frontend_shared/lib/utils'

const props = defineProps<{ class?: string }>()
</script>

<template>
    <tr :class="cn('transition-colors [&_td:first-child]:rounded-l-sm [&_td:last-child]:rounded-r-sm [&_td]:border-r [&_td]:border-r-background [&_td:last-child]:border-none', props.class)">
        <slot/>
    </tr>
</template>
