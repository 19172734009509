import {cva} from "class-variance-authority";
import {ref} from "vue";
import {cn} from "@frontend_shared/lib/utils";

export {default as Button} from "./Button.vue";

export type TButtonAs = 'button' | 'a';
export type TButtonVariant = 'base' | 'default' | 'primary' | 'gradient' | 'outline' | 'outline-2' | 'icon' | 'circle' | 'wrap';
export type TButtonSize = '2xs' | 'xs' | 'sm' | 'md' | 'xl';

export const buttonVariants = (config: { variant: TButtonVariant, size: TButtonSize }, props?: {
    classes?: string | string[] | object | unknown,
    loading?: boolean,
    active?: boolean
}) => {
    const loadingClasses = [
        '[&.is-loading]:text-transparent',
        '[&.is-loading]:pointer-events-none',
    ];
    const cvaBase = cn([
            `btn-${config.variant}`,
            `btn-size-${config.size}`
        ],
        [
            'btn',
            'relative',
            'inline-flex',
            'items-center',
            'justify-center',
            'rounded-md',
            'text-base',
            'font-medium',
            'transition-colors',
            'select-none',
            'disabled:pointer-events-none',
            'disabled:opacity-50'
        ],
        props?.loading ? loadingClasses : [],
        props?.active ? ['[&.is-active]:pointer-events-none'] : [],
        {
            'is-loading': props?.loading,
            'is-active': props?.active
        }
    );

    const cvaConfig = ref({
        variants: {
            variant: {
                default: 'text-lg shadow-button bg-purple-gray bg-opacity-50 text-white hover:bg-purple disabled:bg-gray disabled:text-white/50',
                primary: 'text-violet text-base bg-light-gray hover:text-purple disabled:bg-light-gray disabled:text-violet/50 [&.is-active]:bg-violet', //gradient
                gradient: 'text-lg shadow-button bg-gradient-to-r from-gradient-violet-from to-gradient-violet-to text-white hover:from-gradient-violet-hover-from hover:to-gradient-violet-hover-to disabled:from-gradient-violet-from/50 disabled:to-gradient-violet-to/50 disabled:text-white/50',
                outline: 'text-violet border border-2 border-input border-purple bg-white hover:bg-purple hover:border-purple hover:text-white disabled:border-none disabled:bg-gray disabled:text-white/50',  //gradient
                'outline-2': 'text-violet border border border-input border-gray bg-white hover:bg-purple hover:border-purple hover:text-white disabled:border-none disabled:bg-gray disabled:text-white/50',  //gradient
                icon: 'text-violet bg-light-gray hover:text-white hover:bg-purple disabled:bg-light-gray disabled:text-violet/50 [&.is-active]:bg-violet',
                link: 'text-primary underline-offset-4 hover:underline',
                circle: 'text-lg justify-center !rounded-full shadow-button bg-gradient-to-r from-gradient-violet-from to-gradient-violet-to text-white hover:from-gradient-violet-hover-from hover:to-gradient-violet-hover-to disabled:from-gradient-violet-from/50 disabled:to-gradient-violet-to/50 disabled:text-white/50',
            },
            size: {
                '2xs': 'h-[30px] w-[30px] min-h-[30px] min-w-[30px] rounded-sm px-1 py-1 shadow-none',
                xs: '!text-base h-8 rounded-sm px-15px py-2',
                sm: 'h-10 rounded-sm px-15px py-2',
                md: 'h-50px px-15px py-3.5',
                xl: 'text-xl h-[5.625rem] rounded-lg px-15px px-7 [&.is-loading]:after:w-11 [&.is-loading]:after:h-11',
            },
        },
        compoundVariants: [
            {
                variant: ["gradient", "default"],
                size: "sm",
                class: ['shadow-inner-button-sm-inner'],
            },
            {
                variant: ["gradient", "default"],
                size: "md",
                class: ['shadow-inner-button-md-inner'],
            },
            {
                variant: ["gradient", "default"],
                size: "xl",
                class: ['shadow-inner-button-xl-inner'],
            },
            {
                variant: ["circle"],
                size: "2xs",
                class: ['w-[30px]'],
            },
            {
                variant: ["circle"],
                size: "md",
                class: ['w-[50px]'],
            },
        ],
        defaultVariants: {
            variant: 'default',
            size: 'md',
        } as any
    });

    return cva(cvaBase, cvaConfig.value as any)(config as object)
}

export const loaderVariants = cva(
    [
        'absolute',
        'top-1/2',
        'left-1/2',
        'transform',
        '-translate-x-1/2',
        '-translate-y-1/2',
    ],
    {
        variants: {
            size: {
                '2xs': 'w-4 h-4',
                xs: 'w-22px h-22px',
                sm: 'w-22px h-22px',
                md: 'w-22px h-22px',
                xl: 'w-11 h-11',
            },
        }
    }
)
