import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

export const companyRoutes = [
    {
        path: '/company',
        name: 'company',
        component: () => import("@frontend_pages/company/CompanyRouterView.vue"),
        children: [
            {
                path: '/company/profile',
                name: 'company.profile.show',
                component: () => import("@frontend_pages/company/CompanyProfile.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Profile',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/company/products',
                name: 'company.products.index',
                component: () => import("@frontend_pages/company/CompanyProductIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Products and services',
                    guard: ERouteGuard.AUTHORISED,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_PRODUCTS
                },
            },
            {
                path: '/company/orders',
                name: 'company.orders.index',
                component: () => import("@frontend_pages/company/CompanyProductOrderIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Requests',
                    guard: ERouteGuard.AUTHORISED,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_ORDERS
                },
            },
            {
                path: '/company/reviews',
                name: 'company.reviews.index',
                component: () => import("@frontend_pages/company/CompanyReviewIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Reviews',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/company/push-notifications',
                name: 'company.push-notifications.index',
                component: () => import("@frontend_pages/company/CompanyPushNotificationIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Push notifications',
                    guard: ERouteGuard.AUTHORISED,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_PUSH_NOTIFICATIONS
                },
            },
            {
                path: '/company/ad-notifications',
                name: 'company.ad-notifications.index',
                component: () => import("@frontend_pages/company/CompanyAdNotificationIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Advertising notices',
                    guard: ERouteGuard.AUTHORISED,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_AD_NOTIFICATIONS
                },
            },
        ]
    },
] as const satisfies readonly FrontendRouteRecordType[];
