import type {TSelectOption} from "@frontend_shared/ui/form/select";

export type TMeta = {
    id: number;
    hash: string;
    companyId: number;
    folder_id: number | null;
    folder: TMetaFolder | null;
    is_stream: boolean;
    with_marker_mirror: boolean;
    category: EMetaCategory;
    name: string | null;
    data: any | null;
    amount: number;
    is_partially: boolean;
    arMap: TArMap[];
    mediaTable: TMediaTable[];
    localPath: string;
    cloudPath: string;
    printFilesLink: string;
    pdfFileLink: string | null;
    directLink: string;
    disabled: boolean;
    unlimited: boolean;
    progress: number | null;
    vm_ip: string | null;
    status: EMetaStatus;
    error: string | null;
    qrCode: string | null;
};

export type TArMap = {
    id: number;
    meta_id: number;
    name: string | null;
    marker_file_name: string | null;
    marker_i: number;
    mediaContent: Array<any>;
};

export type TMetaFolder = {
    id: number;
    count: number;
    is_stream: boolean | null;
    with_marker_mirror: boolean | null;
    name: string | null;
};

export type TMediaTable = {
    id: number;
    meta_id: number;
    mc_id: number;
    relativePath: string;
    type: EMediaTableType;
    size: number;
    data: Array<any> | null;
    cloudLink: string | null;
    cloudThumbLink: string | null;
    status: EMediaTableStatus | null;
    error: string | null;
    modified_date: string;
};

export type TMetaResult = {
    cloudPath: string | null;
    directLink: string | null;
    error: string | null;
    status: EMetaStatus;
    arMap: Array<number>;
};

export type TMetaProgress = {
    id: number;
    error: string | null;
    status: EMetaStatus;
    arMap: Array<number>;
    done: number;
    total: number;
};

export type TMetaFolderProgress = {
    id: number;
    progress: TMetaProgress[];
    status: EMetaFolderStatus;
    error: string|null;
    errorCode: EMetaStatus|null;
    done: number;
};

export enum EMediaTableType {
    MARKER = 1,
    MARKER_MIRROR = 101,
    AR_VIDEO = 2,
    MASK = 3,
    EFFECT_ALPHA_VIDEO = 4,
    EFFECT_ALPHA_V_VIDEO = 5,
    LOGO_COMPANY = 6,
    PAGE_LAYOUT = 7,
    AR_PHOTO = 8,
    AR_BACK_VIDEO = 9,
    AR_ALPHA_VIDEO = 10,
    AR_ALPHA_V_VIDEO = 11,
    AUDIO = 12,
    CONTENT_3D = 13,
}

export enum EMediaTableStatus {
    NONE = 0,
    UPLOADED = 1,
    CONVERTED = 2,
    CLOUD_UPLOADED = 3,
    UPDATED = 21,
    UPLOADED_ERROR = 51,
    CONVERTED_ERROR = 52,
    CLOUD_UPLOADED_ERROR = 53,
}

export enum EMetaCategory {
    CATEGORY_PHOTO_ALBUM = 1,
    CATEGORY_PHOTO_BOOK = 2,
    CATEGORY_PHOTO = 3,
    CATEGORY_MAGIC_PHOTO = 4,
    CATEGORY_LEAFLET = 5,
    CATEGORY_CARD = 6,
    CATEGORY_CALENDAR_A3 = 7,
    CATEGORY_CALENDAR_DESKTOP = 8,
    CATEGORY_CALENDAR_CIRCLE = 9,
    CATEGORY_3D = 10,
    CATEGORY_SELF_LIVING = 11,
    CATEGORY_DOUBLE_SELF_LIVING = 12,
}

export const VMetaCategory = {
    1: '!Photo Album!',
    2: 'Live photos',
    3: '!Photo!',
    4: '!Magic photo!',
    5: 'Leaflet',
    6: 'Business card',
    7: '!Calendar A3!',
    8: '!Calendar Desktop!',
    9: 'Calendar Circle',
    10: '3D',
    11: 'Self-Living',
    12: 'Double Self-Living',
} as const;

export const OMetaCategory: TSelectOption[] = [
    {label: VMetaCategory[EMetaCategory.CATEGORY_PHOTO_ALBUM], value: EMetaCategory.CATEGORY_PHOTO_ALBUM},
    {label: VMetaCategory[EMetaCategory.CATEGORY_PHOTO_BOOK], value: EMetaCategory.CATEGORY_PHOTO_BOOK},
    {label: VMetaCategory[EMetaCategory.CATEGORY_PHOTO], value: EMetaCategory.CATEGORY_PHOTO},
    {label: VMetaCategory[EMetaCategory.CATEGORY_MAGIC_PHOTO], value: EMetaCategory.CATEGORY_MAGIC_PHOTO},
    {label: VMetaCategory[EMetaCategory.CATEGORY_LEAFLET], value: EMetaCategory.CATEGORY_LEAFLET},
    {label: VMetaCategory[EMetaCategory.CATEGORY_CARD], value: EMetaCategory.CATEGORY_CARD},
    {label: VMetaCategory[EMetaCategory.CATEGORY_CALENDAR_A3], value: EMetaCategory.CATEGORY_CALENDAR_A3},
    {label: VMetaCategory[EMetaCategory.CATEGORY_CALENDAR_DESKTOP], value: EMetaCategory.CATEGORY_CALENDAR_DESKTOP},
    {label: VMetaCategory[EMetaCategory.CATEGORY_CALENDAR_CIRCLE], value: EMetaCategory.CATEGORY_CALENDAR_CIRCLE},
    {label: VMetaCategory[EMetaCategory.CATEGORY_3D], value: EMetaCategory.CATEGORY_3D},
    {label: VMetaCategory[EMetaCategory.CATEGORY_SELF_LIVING], value: EMetaCategory.CATEGORY_SELF_LIVING},
    {label: VMetaCategory[EMetaCategory.CATEGORY_DOUBLE_SELF_LIVING], value: EMetaCategory.CATEGORY_DOUBLE_SELF_LIVING},
] as const;


export enum EMetaActiveCategory {
    CATEGORY_PHOTO_BOOK = 2,
    CATEGORY_LEAFLET = 5,
    CATEGORY_CARD = 6,
    CATEGORY_CALENDAR_CIRCLE = 9,
    CATEGORY_SELF_LIVING = 11,
    CATEGORY_DOUBLE_SELF_LIVING = 12,
}

export const VMetaActiveCategory = {
    2: 'Live photos',
    5: 'Leaflet',
    6: 'Business card',
    9: 'Calendar Circle',
    11: 'Self-Living',
    12: 'Double Self-Living',
} as const;

export const OMetaActiveCategory: TSelectOption[] = [
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_PHOTO_BOOK], value: EMetaActiveCategory.CATEGORY_PHOTO_BOOK},
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_LEAFLET], value: EMetaActiveCategory.CATEGORY_LEAFLET},
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_CARD], value: EMetaActiveCategory.CATEGORY_CARD},
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_CALENDAR_CIRCLE], value: EMetaActiveCategory.CATEGORY_CALENDAR_CIRCLE},
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_SELF_LIVING], value: EMetaActiveCategory.CATEGORY_SELF_LIVING},
    {label: VMetaActiveCategory[EMetaActiveCategory.CATEGORY_DOUBLE_SELF_LIVING], value: EMetaActiveCategory.CATEGORY_DOUBLE_SELF_LIVING},
] as const;

export enum EMetaStatus {
    NEW = 0,
    CONVERTING = 1,
    CONVERTED = 2,
    CLOUD_UPLOADING = 11,
    CLOUD_UPDATED = 12,
    DONE = 20,
    CONVERTED_ERROR = 51,
    CLOUD_UPLOADED_ERROR = 52,
}

export const VMetaStatus = {
    0: 'New',
    1: 'Converting',
    2: 'Converted',
    11: 'Cloud uploading',
    12: 'Cloud updated',
    20: 'Done',
    51: 'Converted error',
    52: 'Cloud uploaded error',
} as const;

export const OMetaStatus: TSelectOption[] = [
    {label: VMetaStatus[EMetaStatus.NEW], value: EMetaStatus.NEW},
    {label: VMetaStatus[EMetaStatus.CONVERTING], value: EMetaStatus.CONVERTING},
    {label: VMetaStatus[EMetaStatus.CONVERTED], value: EMetaStatus.CONVERTED},
    {label: VMetaStatus[EMetaStatus.CLOUD_UPLOADING], value: EMetaStatus.CLOUD_UPLOADING},
    {label: VMetaStatus[EMetaStatus.CLOUD_UPDATED], value: EMetaStatus.CLOUD_UPDATED},
    {label: VMetaStatus[EMetaStatus.DONE], value: EMetaStatus.DONE},
    {label: VMetaStatus[EMetaStatus.CONVERTED_ERROR], value: EMetaStatus.CONVERTED_ERROR},
    {label: VMetaStatus[EMetaStatus.CLOUD_UPLOADED_ERROR], value: EMetaStatus.CLOUD_UPLOADED_ERROR},
] as const;

export enum EMetaQrCodeFormat {
    PNG = 'png',
    SVG = 'svg',
}

export const VMetaQrCodeFormat = {
    'png': 'PNG',
    'svg': 'SVG',
} as const;

export const OMetaQrCodeFormat: TSelectOption[] = [
    {label: VMetaQrCodeFormat[EMetaQrCodeFormat.PNG], value: EMetaQrCodeFormat.PNG},
    {label: VMetaQrCodeFormat[EMetaQrCodeFormat.SVG], value: EMetaQrCodeFormat.SVG},
] as const;

export enum EMetaQrCodeLocation {
    FRONT = 1,
    BACK = 2,
    DOWNLOAD = 3,
}

export const VMetaQrCodeLocation = {
    1: 'On the front side',
    2: 'On the back',
    3: 'Download QR code separately',
} as const;

export const OMetaQrCodeLocation: TSelectOption[] = [
    {label: VMetaQrCodeLocation[EMetaQrCodeLocation.FRONT], value: EMetaQrCodeLocation.FRONT},
    {label: VMetaQrCodeLocation[EMetaQrCodeLocation.BACK], value: EMetaQrCodeLocation.BACK},
    {label: VMetaQrCodeLocation[EMetaQrCodeLocation.DOWNLOAD], value: EMetaQrCodeLocation.DOWNLOAD},
] as const;

export enum EMetaQrCodePosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    CENTER = 'center',
    ARBITRARILY = 'arbitrarily'
}

export const VMetaQrCodePosition = {
    'top-left': 'Top Left',
    'top-right': 'Top Right',
    'bottom-left': 'Bottom Left',
    'bottom-right': 'Bottom Right',
    'center': 'Center',
    'arbitrarily': 'Arbitrarily',
} as const;

export const OMetaQrCodePosition: TSelectOption[] = [
    {label: VMetaQrCodePosition[EMetaQrCodePosition.TOP_LEFT], value: EMetaQrCodePosition.TOP_LEFT},
    {label: VMetaQrCodePosition[EMetaQrCodePosition.TOP_RIGHT], value: EMetaQrCodePosition.TOP_RIGHT},
    {label: VMetaQrCodePosition[EMetaQrCodePosition.BOTTOM_LEFT], value: EMetaQrCodePosition.BOTTOM_LEFT},
    {label: VMetaQrCodePosition[EMetaQrCodePosition.BOTTOM_RIGHT], value: EMetaQrCodePosition.BOTTOM_RIGHT},
    {label: VMetaQrCodePosition[EMetaQrCodePosition.CENTER], value: EMetaQrCodePosition.CENTER},
    {label: VMetaQrCodePosition[EMetaQrCodePosition.ARBITRARILY], value: EMetaQrCodePosition.ARBITRARILY},
] as const;

export enum EMetaFolderStatus {
    NEW = 0,
    PROCESSING = 1,
    DONE = 2,
    ERROR = 9,
}

export const VMetaFolderStatus = {
    0: 'New',
    1: 'In process',
    2: 'Done',
    9: 'Error'
} as const;

export const OMetaFolderStatus: TSelectOption[] = [
    {label: VMetaFolderStatus[EMetaFolderStatus.NEW], value: EMetaFolderStatus.NEW},
    {label: VMetaFolderStatus[EMetaFolderStatus.PROCESSING], value: EMetaFolderStatus.PROCESSING},
    {label: VMetaFolderStatus[EMetaFolderStatus.DONE], value: EMetaFolderStatus.DONE},
    {label: VMetaFolderStatus[EMetaFolderStatus.ERROR], value: EMetaFolderStatus.ERROR},
] as const;
