import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

export const calendarRoutes = [
    {
        path: '/calendar/circle',
        name: 'calendars.circle.create',
        component: () => import("@frontend_pages/calendars/circle/CalendarCircleCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating calendar',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.CALENDAR
        },
    },
    {
        path: '/calendar/circle/:id',
        name: 'calendars.circle.edit',
        component: () => import("@frontend_pages/calendars/circle/CalendarCircleEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing calendar',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.CALENDAR
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
