import {useAxios} from "@vueuse/integrations/useAxios";
import {authHeader} from "@frontend_entities/auth/lib/authHeader";
import {useAuthStore} from "@frontend_entities/auth/model/store";
import type {StrictUseAxiosReturn} from "@vueuse/integrations";
import type {TCompanyChangePassword, TCompanyChangeTariff, TCompanyContactUs, TCompanyUpBalance, TCompanyUpdate} from "../model/types";
import {useAxiosOptions} from "@frontend_entities/app/lib/useAxiosOptions";
import {useCompanyStatisticStore} from "@frontend_shared/statistic/model/store";

const API_PATH = '/api/companies';

const useCompanyService = () => {
    const update = async (payload: TCompanyUpdate): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH,
            {
                method: 'PATCH',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute({
            data: payload
        })

        if (response.data.value?.data?.viewer) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer);
        }

        return response;
    }

    const upBalance = async (payload: TCompanyUpBalance): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/up-balance',
            {
                method: 'PATCH',
                headers: authHeader()
            },
            useAxiosOptions
        );

        return await execute({
            data: payload
        })
    }

    const changeTariff = async (payload: TCompanyChangeTariff): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/change-subscription-tariff',
            {
                method: 'PATCH',
                headers: authHeader()
            },
            useAxiosOptions
        );

        return await execute({
            data: payload
        })
    }

    const changePassword = async (payload: TCompanyChangePassword): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/change-password',
            {
                method: 'PATCH',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute({
            data: payload
        })

        if (response.data.value?.data?.viewer) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer, response.data.value.data.token);
        }

        return response;
    }

    const contactUs = async (payload: TCompanyContactUs): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/contact-us',
            {
                method: 'POST',
                headers: authHeader()
            },
            useAxiosOptions
        );

        return await execute({
            data: payload
        });
    }

    const getStatistic = async (): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/statistic',
            {
                method: 'GET',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute()

        if (response.data.value?.data) {
            const {setStatistic} = useCompanyStatisticStore();
            await setStatistic(response.data.value.data);
        }

        return response;
    }

    return {update, changePassword, upBalance, changeTariff, getStatistic, contactUs};
}

export default useCompanyService;
