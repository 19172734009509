<script setup lang="ts">
import {cn} from '@frontend_shared/lib/utils'

const props = defineProps<{ class?: string, containerClasses?: string }>()
</script>

<template>
    <div :class="cn('w-full overflow-auto', containerClasses)">
        <table :class="cn('w-full caption-bottom text-sm', props.class)">
            <slot/>
        </table>
    </div>
</template>
