<script setup lang="ts">
import {cn} from '@frontend_shared/lib/utils'

const props = defineProps<{ class?: string }>()
</script>

<template>
    <tbody :class="cn('[&_tr:nth-child(even)]:bg-white [&_tr:nth-child(odd)]:bg-light-gray', props.class)">
    <slot/>
    </tbody>
</template>
