import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const livelyPhotoRoutes = [
    {
        path: '/photo-book',
        name: 'lively-photos.create',
        component: () => import("@frontend_pages/lively-photos/LivelyPhotoCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating lively photos',
            guard: ERouteGuard.AUTHORISED
        },
    },
    {
        path: '/photo-book/:id',
        name: 'lively-photos.edit',
        component: () => import("@frontend_pages/lively-photos/LivelyPhotoEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing lively photos',
            guard: ERouteGuard.AUTHORISED
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
