import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const toBookRoutes = [
    {
        path: '/tobook',
        name: 'to-book.one',
        component: () => import("@frontend_pages/to-book/One.vue"),
        meta: {
            layout: FrontendLayoutsEnum.empty,
            title: 'Book a platform Ojv!',
            guard: ERouteGuard.ANY,
        },
    },
    {
        path: '/tobook2',
        name: 'to-book.two',
        component: () => import("@frontend_pages/to-book/Two.vue"),
        meta: {
            layout: FrontendLayoutsEnum.empty,
            title: 'Book a platform Ojv!',
            guard: ERouteGuard.ANY,
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
