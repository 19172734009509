import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const paymentTransactionRoutes = [
    {
        path: '/payment-transactions',
        name: 'payment-transactions.index',
        component: () => import("@frontend_pages/payment-transactions/PaymentTransactionIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Recharge History',
            guard: ERouteGuard.AUTHORISED
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
