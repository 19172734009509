import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const componentRoutes = [
    {
        path: '/components',
        name: 'components.index',
        component: () => import("@frontend_pages/components/ComponentsIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Components',
            guard: ERouteGuard.AUTHORISED
        },
        children: [
            {
                path: '/components/different',
                name: 'components.different',
                component: () => import("@frontend_pages/components/index/ComponentsDifferent.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Different',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/components/buttons',
                name: 'components.buttons',
                component: () => import("@frontend_pages/components/index/ComponentsButtons.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Buttons',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/components/form',
                name: 'components.form',
                component: () => import("@frontend_pages/components/index/ComponentsForm.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Form',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/components/table',
                name: 'components.table',
                component: () => import("@frontend_pages/components/index/ComponentsTable.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Table',
                    guard: ERouteGuard.AUTHORISED
                },
            },
            {
                path: '/components/sandbox',
                name: 'components.sandbox',
                component: () => import("@frontend_pages/components/index/ComponentsSandbox.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Sandbox',
                    guard: ERouteGuard.AUTHORISED
                },
            }
        ]
    },
] as const satisfies readonly FrontendRouteRecordType[];
