<script setup lang="ts">
import {Tab, Tabs, type TTab} from "@frontend_widgets/tabs";
import {type Ref, ref} from "vue";
import {useLocaleStore} from "@frontend_entities/locale";
import {TitleText} from "@frontend_shared/ui/title-text";

const {$t} = useLocaleStore();
type TTabWithTitle = TTab & {title: string};
const tabs: Ref<TTabWithTitle[]> = ref([
    {
        title: $t('Different'),
        route: {name: 'components.different'}
    },
    {
        title: $t('Buttons'),
        route: {name: 'components.buttons'}
    },
    {
        title: $t('Form'),
        route: {name: 'components.form'}
    },
    {
        title: $t('Table'),
        route: {name: 'components.table'}
    },
    {
        title: $t('Sandbox'),
        route: {name: 'components.sandbox'}
    },
])
</script>

<template>
    <TitleText as="h1" size="xl" weight="medium" class="mt-6 mb-3">{{ $t('Components') }}</TitleText>
    <Tabs>
        <Tab v-for="tab in tabs" :route="tab.route">{{ $t(tab.title)}}</Tab>
    </Tabs>
    <router-view/>
</template>

<style scoped lang="scss">

</style>
