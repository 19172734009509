import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

export const selfLivingRoutes = [
    {
        path: '/self-living',
        name: 'self-lively-photos.create',
        component: () => import("@frontend_pages/self-livings/SelfLivingCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating self-living',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.SELF_LIVING
        },
    },
    {
        path: '/self-living/:id',
        name: 'self-lively-photos.edit',
        component: () => import("@frontend_pages/self-livings/SelfLivingEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing self-living',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.SELF_LIVING
        },
    },
    {
        path: '/double-self-living',
        name: 'double-self-lively-photos.create',
        component: () => import("@frontend_pages/self-livings/DoubleSelfLivingCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating double self-living',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.DOUBLE_SELF_LIVING
        },
    },
    {
        path: '/double-self-living/:id',
        name: 'double-self-lively-photos.edit',
        component: () => import("@frontend_pages/self-livings/DoubleSelfLivingEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing double self-living',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.DOUBLE_SELF_LIVING
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
