<script setup lang="ts">
import {cn} from '@frontend_shared/lib/utils'

const props = defineProps<{ class?: string }>()
</script>

<template>
    <th :class="cn('h-10 text-left text-violet/50 align-middle font-normal', props.class)">
        <slot/>
    </th>
</template>
