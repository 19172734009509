import {acceptHMRUpdate, defineStore} from "pinia";
import {createI18n, type I18n} from "vue-i18n";
import type {App, Plugin, Ref} from "vue";
import {ref, toValue} from "vue";
import {ELang} from "./types";
import {useCookies} from "@vueuse/integrations/useCookies";

export const useLocaleStore = defineStore('locale', () => {
    let $locale: Ref<ELang> = getDefaultLocale();
    const messages = JSON.parse(document.querySelector('meta[name="i18n"]')?.getAttribute('content') || "{}");

    const i18n: I18n = createI18n({
        legacy: false, // you must set `false`, to use Composition API
        locale: toValue($locale), // set locale
        fallbackLocale: 'ru', // set fallback locale
        messages
    });

    const $t = i18n.global.t as any;

    function setLocale(lang: ELang) {
        const cookies = useCookies();
        cookies.remove('ojv_locale');
        cookies.set('ojv_locale', lang, {
            expires: new Date(new Date().getTime() + 576000 * 60 * 1000),
            // httpOnly: true,
            sameSite: 'lax',
            path: '/',
        });
        $locale.value = lang;
        /* @ts-ignore */
        i18n.global.locale.value = lang;
    }

    function getDefaultLocale(): Ref<ELang> {
        const cookies = useCookies();
        if (cookies.get('ojv_locale')) {
            return ref(cookies.get('ojv_locale'));
        }

        return ref(ELang.LANG_RU);
    }

    return {i18n, $t, $locale, setLocale}
});

export const TranslatePlugin: Plugin = {
    install: (app: App) => {
        const {$t, $locale, i18n} = useLocaleStore();

        app.config.globalProperties.$t = $t;
        app.config.globalProperties.$locale = $locale;
        app.use(i18n);
    }
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLocaleStore, import.meta.hot))
}
