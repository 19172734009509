import {ESubscriptionTariffType} from "@frontend_entities/subscription";

export type TAuthHeader = {
    Authorization?: string;
    Locale?: string;
};

export type TLoginUser = {
    email: string;
    password: string;
};

export type TRegisterUser = TLoginUser & {
    phone: string;
    invitationCode?: string;
    type?: EAuthSubscriptionType;
};

export type TForgotPassword = {
    email: string;
};

export type TResetPassword = {
    password: string;
    email: string;
    token: string;
};

export enum EAuthSubscriptionType {
    DEMO = 'demo',
    DEFAULT = 'default',
    SUBSCRIPTION = 'subscription',
}
