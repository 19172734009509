import {acceptHMRUpdate, defineStore} from "pinia";
import {ref, type Ref} from "vue";
import type {TSubscriptionSaleTariff} from "../../option/model/types";

export const useSubscriptionSaleTariffStore = defineStore('subscription-sale-tariff', () => {
    const subscriptionSaleTariffs: Ref<TSubscriptionSaleTariff[] | null> = ref(null);

    function setSaleAll(payload: TSubscriptionSaleTariff[]): void {
        subscriptionSaleTariffs.value = payload;
    }

    return {
        setSaleAll,
        subscriptionSaleTariffs,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSubscriptionSaleTariffStore, import.meta.hot))
}
