import {storeToRefs} from 'pinia'
import {type SubmissionHandler, useForm} from 'vee-validate';
import * as Yup from 'yup';
import type {TLoginUser} from "../model/types";
import useAuthService from "../api/service";
import {useAuthStore} from "../model/store";
import {useMutation} from "@tanstack/vue-query";
import {EUserRole} from "@frontend_entities/user";
import type {AxiosError} from "axios";
import type {TServerResponse} from "@frontend_entities/app";
import {useRoute, useRouter} from "vue-router";
import {type Ref, ref} from "vue";
import {scrollToError} from "@frontend_shared/lib/utils";

const schema = Yup.object({
    email: Yup.string().email('Please enter valid email').required('The email field is required'),
    password: Yup.string().required('The password field is required'),
});

export const useLoginForm = () => {
    const AuthService = useAuthService();
    const AuthStore = useAuthStore();
    const form: Ref<TLoginUser> = ref({
        email: '',
        password: '',
    });
    const {isAuth} = storeToRefs(AuthStore);
    const route = useRoute();
    const router = useRouter();

    const {errors, setErrors, handleSubmit} = useForm<TLoginUser>({
        initialValues: form.value,
        validationSchema: schema,
    });


    const {mutate, isPending} = useMutation({
        mutationFn: (payload: TLoginUser) => AuthService.login(payload),
        async onSuccess(data, variables, context) {
            if (!isAuth) {
                await AuthService.logout();
            }

            if (AuthStore.userHasRole([EUserRole.ADMIN, EUserRole.MANAGER])) {
                location.href = '/admin';
            } else {
                await router.push(route.query?.redirect  ? {path: route.query.redirect as string} : {name: 'home'});
            }
        },
        onError(error: AxiosError, variables, context) {
            setErrors((error?.response?.data as TServerResponse)?.errors as any);
        },
    });

    const submit: SubmissionHandler = handleSubmit((values) => {
        mutate(values);
    }, () => scrollToError()) as unknown as SubmissionHandler;

    return {form, errors, isPending, schema, submit}
};
