import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

export const leafletRoutes = [
    {
        path: '/leaflet',
        name: 'leaflets.create',
        component: () => import("@frontend_pages/leaflets/LeafletCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating leaflet',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.LEAFLET
        },
    },
    {
        path: '/leaflet/:id',
        name: 'leaflets.edit',
        component: () => import("@frontend_pages/leaflets/LeafletEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing leaflet',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.LEAFLET
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
