import {acceptHMRUpdate, defineStore} from "pinia";
import {ref, type Ref} from "vue";
import type {TSubscriptionOption} from "../model/types";

export const useSubscriptionOptionStore = defineStore('subscription-option', () => {
    const subscriptionOptions: Ref<TSubscriptionOption[] | null> = ref(null);

    function setAll(payload: TSubscriptionOption[]): void {
        subscriptionOptions.value = payload;
    }

    return {
        setAll,
        subscriptionOptions,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSubscriptionOptionStore, import.meta.hot))
}
