<script setup
        lang="ts">
import {Tab, Tabs} from "@frontend_widgets/tabs";
import {MainTitleBox} from "@frontend_widgets/main-title-box";
import {TitleText} from "@frontend_shared/ui/title-text";
import {cn} from "@frontend_shared/lib/utils";
import {useCompanyStatisticStore} from "@frontend_shared/statistic";
import {storeToRefs} from "pinia";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";
import {useAuthStore} from "@frontend_entities/auth/model/store";

const CompanyStatisticStore = useCompanyStatisticStore();
const {companyStatistic} = storeToRefs(CompanyStatisticStore);

const AuthStore = useAuthStore();

const newItemClasses = cn(['inline-flex', 'justify-center', 'items-center', 'text-xs', 'text-white', 'font-bold', 'bg-purple', 'rounded-full', 'w-15px', 'h-15px', 'ml-1']);
</script>

<!--absolute left-[calc(100%+0.25rem)] inline-flex justify-center items-center text-xs text-white font-bold bg-purple rounded-full w-15px h-15px -top-px-->
<template>
    <MainTitleBox class="border-b-0">
        <TitleText as="h1"
                   size="xl"
                   weight="medium"
                   class="mt-1.5">{{ $t('Personal account') }}
        </TitleText>
    </MainTitleBox>
    <Tabs class="-mt-0.5">
        <Tab :route="{name: 'company.profile.show'}">{{ $t('Profile') }}</Tab>
        <Tab v-if="AuthStore.userSubscriptionHasOption(ESubscriptionTariffOptionCode.PROFILE_PRODUCTS)"
             :route="{name: 'company.products.index'}">{{ $t('Products and services') }}
        </Tab>
        <Tab v-if="AuthStore.userSubscriptionHasOption(ESubscriptionTariffOptionCode.PROFILE_ORDERS)"
             :route="{name: 'company.orders.index'}">
            {{ $t('Requests') }}
            <div v-if="companyStatistic?.orders_new_count"
                 :class="newItemClasses">{{ companyStatistic?.orders_new_count }}
            </div>
        </Tab>
        <Tab :route="{name: 'company.reviews.index'}">
            {{ $t('Reviews') }}
            <div v-if="companyStatistic?.reviews_new_count"
                 :class="newItemClasses">{{ companyStatistic?.reviews_new_count }}
            </div>
        </Tab>
        <Tab v-if="AuthStore.userSubscriptionHasOption(ESubscriptionTariffOptionCode.PROFILE_PUSH_NOTIFICATIONS)"
             :route="{name: 'company.push-notifications.index'}">{{ $t('Push notifications') }}
        </Tab>
        <Tab v-if="AuthStore.userSubscriptionHasOption(ESubscriptionTariffOptionCode.PROFILE_AD_NOTIFICATIONS)"
             :route="{name: 'company.ad-notifications.index'}">{{ $t('Advertising notices') }}
        </Tab>
    </Tabs>
    <router-view class="mt-4"/>
</template>

<style scoped
       lang="scss">

</style>
