import type {UseAxiosOptions} from "@vueuse/integrations/useAxios";
import {useAuthService, useAuthStore} from "@frontend_entities/auth";
import {scrollToError} from "@frontend_shared/lib/utils";
import {nextTick} from "vue";
import type {AxiosError} from "axios";
import type {TServerResponse} from "@frontend_entities/app";

export const useAxiosOptions: UseAxiosOptions = {
    immediate: false,
    onError(error: any) {

        console.log('error', error);

        if (error?.response?.status === 401) {
            const AuthService = useAuthService();
            const AuthStore = useAuthStore();
            if (AuthStore.isAuth) {
                AuthService.logout();
                location.href = '/login';
            }
        }

        if (error?.response?.status === 422) {
            setTimeout(scrollToError, 150)
        }

        if (error?.response?.status === 500) {
            alert(((error as AxiosError)?.response?.data as TServerResponse)?.message ? ((error as AxiosError)?.response?.data as TServerResponse)?.message : (error as AxiosError)?.message);
        }
        /*toast({
            title: 'Error',
            description: ((error as AxiosError)?.response?.data as TServerResponse)?.message ?? (error as AxiosError)?.message,
            variant: 'destructive'
        })*/

        // alert(((error as AxiosError)?.response?.data as TServerResponse)?.message ?? (error as AxiosError)?.message);
    },
}
