import type {Ref} from 'vue';
import {ref} from 'vue';
import {acceptHMRUpdate, defineStore} from 'pinia'
import type {TCurrencyRate} from "./types";


export const useCurrencyRateStore = defineStore('currency-rate', () => {
    let currencyRate: Ref<TCurrencyRate | null> = ref(null);


    const setCurrencyRate = async (payload: TCurrencyRate) => {
        currencyRate.value = payload;
    }

    return {currencyRate, setCurrencyRate}
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCurrencyRateStore, import.meta.hot))
}
