import {acceptHMRUpdate, defineStore} from 'pinia'
import {type Ref, ref} from "vue";
import {type TCompanyStatistic} from "@frontend_entities/company";

export const useCompanyStatisticStore = defineStore('company-statistic', () => {
    const companyStatistic: Ref<TCompanyStatistic | null> = ref(null);

    async function setStatistic(payload: TCompanyStatistic): Promise<void> {
        companyStatistic.value = payload;
    }

    return {companyStatistic, setStatistic}
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCompanyStatisticStore, import.meta.hot))
}
