<script setup lang="ts">
import {cn} from '@frontend_shared/lib/utils'

const props = defineProps<{ class?: string }>()
</script>

<template>
    <td :class="cn(['text-base', 'align-middle', 'px-[0.9375rem]', 'py-1.5'], props.class)">
        <slot/>
    </td>
</template>
