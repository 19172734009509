import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";

export const authRoutes = [
    {
        path: '/login',
        name: 'auth.login',
        component: () => import("@frontend_pages/auth/Login.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Login',
            guard: ERouteGuard.GUEST
        }
    },
    {
        path: '/enroll',
        name: 'auth.register',
        component: () => import("@frontend_pages/auth/Register.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Registration',
            guard: ERouteGuard.GUEST
        }
    },
    {
        path: '/forgot-password',
        name: 'auth.forgot-password',
        component: () => import("@frontend_pages/auth/ForgotPassword.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Password recovery',
            guard: ERouteGuard.GUEST
        }
    },
    {
        path: '/reset-password',
        name: 'auth.reset-password',
        component: () => import("@frontend_pages/auth/ResetPassword.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Reset password',
            guard: ERouteGuard.GUEST
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
