import type {TForgotPassword, TLoginUser, TRegisterUser, TResetPassword} from "../";
import {authHeader, useAuthStore} from "../";
import {useAxios} from "@vueuse/integrations/useAxios";
import type {StrictUseAxiosReturn} from "@vueuse/integrations";
import {useAxiosOptions} from "@frontend_entities/app/lib/useAxiosOptions";

const API_PATH: string = '/api/auth';

const useAuthService = () => {
    const login = async (payload: TLoginUser): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/login',
            {
                method: 'POST'
            },
            useAxiosOptions
        );

        const response = await execute({
            data: payload
        })

        if (response.data.value?.data?.token) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer, response.data.value.data.token);
        } else if (localStorage.hasOwnProperty('ojv-token')) {
            await logout();
        }

        return response;
    }

    const logout = async (): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/logout',
            {
                method: 'POST',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute();
        const {unsetUser} = useAuthStore();

        await unsetUser();

        return response;
    }

    const register = async (payload: TRegisterUser): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/register',
            {
                method: 'POST'
            },
            useAxiosOptions
        );

        const response = await execute({
            data: payload
        })

        if (response.data.value?.data?.token) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer, response.data.value.data.token);
        }

        return response;
    }

    const passwordRecovery = async (payload: TForgotPassword): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/forgot-password',
            {
                method: 'POST'
            },
            useAxiosOptions
        );

        return await execute({
            data: payload
        });
    }

    const resetPassword = async (payload: TResetPassword): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/reset-password',
            {
                method: 'POST'
            },
            useAxiosOptions
        );

        return await execute({
            data: payload
        });
    }

    const leaveImpersonate = async (): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/leave-impersonate',
            {
                method: 'POST',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute();

        if (response.data.value?.data?.token) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer, response.data.value.data.token);
        } else if (localStorage.hasOwnProperty('ojv-token')) {
            await logout();
        }

        return response;
    }

    return {login, logout, register, passwordRecovery, resetPassword, leaveImpersonate};
}

export default useAuthService;
