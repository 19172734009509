import {FrontendLayoutsEnum} from "@frontend_app/layouts/types";
import {ERouteGuard, type FrontendRouteRecordType} from "@frontend_shared/router/types";
import {ESubscriptionTariffOptionCode} from "@frontend_entities/subscription";

export const cardRoutes = [
    {
        path: '/card',
        name: 'cards.create',
        component: () => import("@frontend_pages/cards/CardCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating visiting card',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.VISIT_CARD
        },
    },
    {
        path: '/card/:id',
        name: 'cards.edit',
        component: () => import("@frontend_pages/cards/CardEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing visiting card',
            guard: ERouteGuard.AUTHORISED,
            needSubscriptionOption: ESubscriptionTariffOptionCode.VISIT_CARD
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
