<script setup lang="ts">
import type {TTab} from ".";
import {computed, useAttrs} from "vue";
import {cn} from "@frontend_shared/lib/utils";

const {route, link} = defineProps<TTab>()

const attrs = useAttrs();

const tabClasses = computed(() => cn([
    'text-sm',
    '-mb-px',
    'pb-1.5',
    'text-violet',
    'transition-colors',
    '[&.router-link-active]:text-black',
    '[&.router-link-active]:border-b',
    '[&.router-link-active]:border-b-input',
    '[&.router-link-active]:border-b-purple',
    '[&.router-link-active]:pointer-events-none',
    'hover:border-b',
    'hover:border-b-input',
    'hover:border-b-purple',
], attrs.class as string))
</script>

<template>
    <TheRouterLink v-if="route" :to="route" :class="tabClasses">
        <slot/>
    </TheRouterLink>
    <a v-if="link" v-bind="link" :class="tabClasses">
        <slot/>
    </a>
</template>

<style lang="scss" scoped></style>
