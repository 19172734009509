import type {ClassValue} from "clsx";
import {clsx} from "clsx";
import {twMerge} from "tailwind-merge";
import type {TSelectOption} from "@frontend_shared/ui/form/select";
import {ref} from "vue";
import type {StrictUseAxiosReturn} from "@vueuse/integrations";
import type {RouteLocation} from "vue-router";
import axios from "axios";
import {ERouteGuard} from "@frontend_shared/router/types";

let getBestHostTries = 0;
export const getBestHost = async (): Promise<any> => {
    let result = import.meta.env.VITE_HANDLER_HOST;
    let data = [];
    getBestHostTries++;

    try {
        let response = await axios.get(import.meta.env.VITE_HANDLER_HOST + '/api/servers');
        data = response.data;
    } catch (e) {
        if (getBestHostTries <= 3) {
            return getBestHost();
        }
        getBestHostTries = 0;
        return result;
    }

    getBestHostTries = 0;

    if (Array.isArray(data) && data.length) {
        let min = 999999999;
        let max = 0;
        let host = null;

        for (let i = 0; i < data.length; i++) {
            min = data[i].queue.mediaTables < min ? data[i].queue.mediaTables : min;
            max = data[i].queue.mediaTables > max ? data[i].queue.mediaTables : max;

            host = host && host.queue &&
            host.queue.mediaTables <
            data[i].queue.mediaTables ?
                host :
                data[i];
        }

        if (host.local_ip) {
            result = import.meta.env.VITE_HANDLER_HOST + '/handler/' + host.local_ip;
        } else {
            result = '/web/handler/' + host.ip;
        }
    }
    return result;
}
export const getBestHostTest = async (): Promise<any> => {
    let result = import.meta.env.VITE_HANDLER_HOST;
    let data = [];
    getBestHostTries++;

    try {
        let response = await axios.get(import.meta.env.VITE_HANDLER_HOST + '/api/servers');
        data = response.data;
    } catch (e) {
        if (getBestHostTries <= 3) {
            return getBestHostTest();
        }
        getBestHostTries = 0;
        return result;
    }

    getBestHostTries = 0;

    if (Array.isArray(data) && data.length) {
        let min = 999999999;
        let max = 0;
        let host = null;

        for (let i = 0; i < data.length; i++) {
            min = data[i].queue.mediaTables < min ? data[i].queue.mediaTables : min;
            max = data[i].queue.mediaTables > max ? data[i].queue.mediaTables : max;

            host = host && host.queue &&
            host.queue.mediaTables <
            data[i].queue.mediaTables ?
                host :
                data[i];
        }

        result = import.meta.env.VITE_HANDLER_HOST + '/handler/' + host.ip;
    }
    return result;
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function debounce<T extends Function>(cb: T, wait = 20) {
    let h: any = 0;
    let callable = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => cb(...args), wait);
    };
    return <T>(<any>callable);
}

export function getFileNameByPath(path: string): string {
    if (!path) {
        return '';
    }
    return path?.split('\\')?.pop()?.split('/').pop() ?? '';
}

export function getFileName(payload: File | string | null): string {
    if (!payload) {
        return '';
    }

    if (payload instanceof File) {
        return payload.name;
    } else {
        return getFileNameByPath(payload);
    }
}

export function arrayToSelectOptions(arr: []): TSelectOption[] {
    const result: TSelectOption[] = [];
    arr.forEach(item => {
        result.push({
            value: item,
            label: item
        })
    });
    return result;
}


export function stringIDtoInt(id: string): number {
    return parseInt(id.replace('id', ''), 10);
}

export const isNumeric = (value: any) => {
    return /^-?\d+$/.test(value);
}

export const caretToEnd = (el: HTMLElement) => {
    if (typeof window.getSelection != "undefined"
        && typeof document.createRange != "undefined") {
        let range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        let sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);
    } else if (
        /* @ts-ignore */
        typeof document.body.createTextRange != "undefined"
    ) {
        /* @ts-ignore */
        let textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
}


export function scrollToError(): void {
    console.log('scrollToError');
    document.querySelector('.form-error')?.scrollIntoView({behavior: "smooth", block: "center"});
}

export function isFile(payload: any): boolean {
    return typeof payload?.name == 'string';
}

export function getWidthHeightOutside(boxWidth: number, boxHeight: number, itemWidth: number, itemHeight: number): {
    width: number,
    height: number
} {
    let aWb = boxWidth / itemWidth;
    let aHb = boxHeight / itemHeight;
    let kef = Math.max(aWb, aHb);

    return {
        width: itemWidth * kef,
        height: itemHeight * kef,
    }
}

export function getWidthHeightInside(boxWidth: number, boxHeight: number, itemWidth: number, itemHeight: number): {
    width: number,
    height: number
} {
    let aWb = boxWidth / itemWidth;
    let aHb = boxHeight / itemHeight;
    let kef = Math.min(aWb, aHb);

    return {
        width: itemWidth * kef,
        height: itemHeight * kef,
    }
}

export function addUrlParam(url: string, param: string, value: string) {
    let baseUrl = 'http://temporary-base-url';
    let isRelative = !url.startsWith('http://') && !url.startsWith('https://');
    let fullUrl = isRelative ? new URL(url, baseUrl) : new URL(url);

    fullUrl.searchParams.set(param, value);

    if (isRelative) {
        // Для относительных URL возвращаем только путь и параметры
        return fullUrl.pathname + fullUrl.search;
    } else {
        // Для полных URL возвращаем полный URL
        return fullUrl.toString();
    }
}

export const getFileSrc = (val: File | string | null, def: string | null = null, hash: string | null = null) => {
    let src = def;
    if (val instanceof File) {
        src = window.URL.createObjectURL(val);
    } else if (val && typeof val === 'string') {
        if (hash) {
            src = addUrlParam(val, 'hash', hash);
        } else {
            src = val;
        }
    }

    return src;
}

export const useDownloadFile = () => {
    const isPending = ref(false);
    const downloadFile = (url: string, filename?: string) => {
        isPending.value = true;
        return fetch(url).then(async (t) => {
            await t.blob().then((b) => {
                    if (!filename) {
                        if (url.includes('/api/proxy-download')) {
                            const urlObj = new URL(url, import.meta.env.VITE_APP_URL);
                            url = (urlObj.searchParams.has('url') ? urlObj.searchParams.get('url') : url) as string;
                        }
                        filename = url.split('\\').pop()?.split('/')?.pop()?.split('?')[0]
                    }
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", filename as string);
                    a.click();
                }
            );

            isPending.value = false;
        });
    };

    const downloadBlobFile = async (response: Promise<StrictUseAxiosReturn<any, any, any>>, filename: string) => {
        isPending.value = true;
        return response.then(({data}) => {
            let file = new File([data.value], filename);
            let a = document.createElement("a");
            a.href = URL.createObjectURL(file);
            a.setAttribute("download", filename as string);
            a.click();
        }).finally(() => {
            isPending.value = false;
        });
    };

    return {isPending, downloadFile, downloadBlobFile};
}

export const getSocialIcon = (link: string): string => {
    let result = 'soc_default.png';

    if (~link.indexOf('vk.com')) {
        result = 'vk.png';
    } else if (~link.indexOf('viber')) {
        result = 'wb.png';
    } else if (~link.indexOf('whatsapp') || ~link.indexOf('wa.me')) {
        result = 'wt.png';
    } else if (~link.indexOf('t.me')) {
        result = 'teg.png';
    } else if (~link.indexOf('mail')) {
        result = 'ma.png';
    } else if (~link.indexOf('linkedin.com')) {
        result = 'in.png';
    } else if (~link.indexOf('instagram.com')) {
        result = 'ins.png';
    } else if (~link.indexOf('twitter.com')) {
        result = 'tw.png';
    } else if (~link.indexOf('ok.ru')) {
        result = 'od.png';
    } else if (~link.indexOf('youtube.com')) {
        result = 'yt.png';
    } else if (~link.indexOf('facebook.com')) {
        result = 'fb.png';
    }

    return result;
};

export const dataToFile = (data: string, name: string): File => {
    let blobBin = atob(data.split(',')[1]);
    let array = [];
    for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
    }
    return new File([new Uint8Array(array)], name + '_' + new Date().getUTCMilliseconds() + '.png', {type: 'image/png'});
}


export const dataURLtoFile = (dataUrl: string, filename: string) => {
    let arr: string[] = dataUrl.split(',');
    let mime = (arr[0].match(/:(.*?);/) as string[])[1];
    let bStr = atob(arr[arr.length - 1]);
    let n = bStr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
    }

    let file = new File([u8arr], filename, {type: mime});
    /* @ts-ignore */
    file.isGenerated = true;
    return file;
};

export const urlToFile = async (url: string, filename: string, defaultType = 'image/jpeg') => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename, {
        type: data.type || defaultType,
    });
}

export const resizeScaleImage = (el: HTMLImageElement | HTMLVideoElement, rotate: number, size: {
    width: number,
    height: number
}): string => {
    let canvas = document.createElement('canvas');
    let cContext = canvas.getContext('2d') as CanvasRenderingContext2D;
    let cw = size.width, ch = size.height,
        cwx = size.width, chx = size.height,
        cx = 0, cy = 0;

    //   Calculate new canvas size and x/y coorditates for image
    switch (rotate) {
        case 90:
            cwx = ch;
            chx = cw;
            cy = cw * (-1);
            break;
        case 180:
            cx = cw * (-1);
            cy = ch * (-1);
            break;
        case 270:
            cwx = ch;
            chx = cw;
            cx = ch * (-1);
            break;
    }

    // console.log('cx', cx, 'cy', cy, 'cw', cw, 'ch', ch);

    //  Rotate image
    canvas.setAttribute('width', cw + 'px');
    canvas.setAttribute('height', ch + 'px');
    cContext.rotate(rotate * Math.PI / 180);
    cContext.drawImage(el, cx, cy, cwx, chx);
    return canvas.toDataURL();
}

export const downloadFile = (url: string, filename?: string) => {
    fetch(url).then(async (t) => {
        return await t.blob().then((b) => {
                if (!filename) {
                    filename = url.split('\\').pop()?.split('/')?.pop()?.split('?')[0]
                }
                let a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename as string);
                a.click();
            }
        );
    });
}


export const useWordDeclension = (count: number, one: string, two: string, three: string): string => {
    if (count % 100 >= 11 && count % 100 <= 19) {
        return three;
    } else {
        switch (count % 10) {
            case 1:
                return one;
            case 2:
            case 3:
            case 4:
                return two;
            default:
                return three;
        }
    }
}

export const getDaysHoursBySeconds = (seconds: number) => {
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;

    const secondsInHour = secondsInMinute * minutesInHour;
    const secondsInDay = secondsInHour * hoursInDay;

    const days = Math.floor(seconds / secondsInDay);
    const remainingSeconds = seconds % secondsInDay;
    const hours = Math.floor(remainingSeconds / secondsInHour);

    return {
        days,
        hours
    }
}

export const isValidUrl = (string: string) => {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}


export const isExistUrl = (url: string) => {
    let http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
}


export const getImage = (imageSrc: string, onSuccess: any = null, onFail: any = null) => {
    const img = new Image();
    if (typeof onSuccess === 'function') {
        img.onload = onSuccess;
    }

    if (typeof onFail === 'function') {
        img.onerror = onFail;
    }
    img.src = imageSrc;
    return img;
}

export const getRedirectQuery = (route: RouteLocation) => {
    if (route.meta.guard === ERouteGuard.AUTHORISED) {
        return route.fullPath;
    }

    return null;
}

/*export const downloadFile = (url: string, filename?: string) => {
    fetch(url).then(async (t) => {
        return await t.blob().then((b) => {
                if (!filename) {
                    filename = url.split('\\').pop()?.split('/')?.pop()?.split('?')[0]
                }
                let a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename as string);
                a.click();
            }
        );
    });
}*/
